<template>
	<v-container v-if="$can('create', 'Blog') || $can('update', 'Blog')" fluid>
		<v-row no-gutters>
			<v-col cols="8" class="pr-2">
				<v-card v-if="!loading" class="pa-6 pb-6">
					<FileUploader
						:initFiles="banner ? [banner] : []"
						@errorOccured="dropzoneError"
						:purpose="`banner`"
						:altText="altText"
						:maxFiles="1"
						@changed="fileUploaderChanged"
					/>
					<v-row no-gutters>
						<v-col cols="12" class="pb-3">
							<p class="ma-0 label">Image Alt Text :</p>
							<v-text-field
								placeholder="Enter Alt Text"
								v-model="altText"
								:error-messages="errors.altText"
							></v-text-field>
						</v-col>
						<v-col cols="12" class="pb-3">
							<p class="ma-0 label">Title* :</p>
							<v-text-field
								placeholder="Enter Title"
								v-model="title"
								@input="$v.title.$touch()"
								@blur="$v.title.$touch()"
								:error-messages="titleErrors()"
								required
							></v-text-field>
						</v-col>
						<v-col cols="12" class="pb-3">
							<p class="ma-0 label mb-3">Brief Description* :</p>
							<v-textarea
								auto-grow
								placeholder="Enter a brief description of 2 - 3 lines"
								rows="1"
								class="pa-0 mt-0"
								@input="$v.description.$touch()"
								@blur="$v.description.$touch()"
								v-model="description"
								:error-messages="descriptionErrors()"
							></v-textarea>
						</v-col>
						<v-col cols="12">
							<p class="ma-0 label">Services:</p>
							<Search
								:initialData="services"
								:url="`/${$store.state.app.projectId}/services/search`"
								@search-result="updateService($event)"
							></Search>
						</v-col>

						<v-col cols="12">
							<p class="ma-0 label mt-3">Content* :</p>
							<div id="ckcontent">
								<Editor
									:initData="body"
									:purpose="`create Blog`"
									@changed="editorChanged"
								></Editor>
								<span
									class="error-msg"
									v-for="(error, ind) in bodyErrors()"
									:key="ind"
								>
									{{ error }}
								</span>
							</div>
						</v-col>
					</v-row>
					<v-card-actions class="card-actions px-0">
						<UnoSaveButton
							@click.native="validateData()"
							@primary-button-click="blogListing()"
							primary-button-text="Move To Blog Listing"
							secondary-button-text="Continue Editing"
							:alert="alert"
							:saving="isSaving"
						></UnoSaveButton>

						<UnoPublishButton
							v-if="isEdit && $can('publish', 'Blog')"
							:isPublished="isPublished"
							@click.native="toggleStatus()"
						></UnoPublishButton>

						<v-spacer></v-spacer>

						<UnoDeleteButton
							v-if="isEdit && $can('delete', 'Blog')"
							@confirm="deleteBlog()"
						></UnoDeleteButton>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="4">
				<PageSeoAudit :url-to-search="publicPath" :body="body" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import _ from 'lodash'
import Search from '@/components/Search'
import { required, minLength } from 'vuelidate/lib/validators'
// ACTION BUTTONS
import FileUploader from '@/components/FileUploader'
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import Editor from '@/components/CkEditor'
import PageSeoAudit from '@/components/PageSeoAudit'

export default {
	components: {
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
		FileUploader,
		Editor,
		Search,
		PageSeoAudit,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSaving: false,
			blogId: null,
			isEdit: false,
			loading: true,
			banner: null,
			title: '',
			altText: '',
			slug: '',
			description: '',
			body: '',
			bannerId: null,
			purpose: '',
			services: [],
			publicPath: '',
			isPublished: true,
			errors: {
				altText: '',
				title: '',
				body: '',
				description: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	validations: {
		title: {
			required,
			minLength: minLength(5),
		},
		body: {
			required,
			minLength: minLength(17),
		},
		description: {
			required,
			minLength: minLength(5),
		},
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.blogId = this.id
			this.getBlog()
		} else {
			this.loading = false
		}

		// debounce registering
		this.debouncedAltTextChanged = _.debounce(this.altTextChanged, 500)
	},
	watch: {
		$route: 'getBlog',
		title() {
			this.errors.title = ''
		},
		description() {
			this.errors.description = ''
		},
		body() {
			this.errors.body = ''
		},
		altText() {
			this.altTextChanged()
		},
	},
	methods: {
		titleErrors() {
			const errors = []
			if (!this.$v.title.$dirty) return errors

			!this.$v.title.minLength &&
				errors.push('Title must be atleast 4 letters long')
			!this.$v.title.required && errors.push('Title is required.')

			return errors
		},
		descriptionErrors() {
			const errors = []
			if (!this.$v.description.$dirty) return errors

			!this.$v.description.minLength &&
				errors.push('Description must be atleast 5 letters long')
			!this.$v.description.required &&
				errors.push('Description is required.')
			return errors
		},
		bodyErrors() {
			const errors = []
			if (!this.$v.body.$dirty) return errors

			!this.$v.body.minLength &&
				errors.push('Body must be atleast 10 letters long')
			!this.$v.body.required && errors.push('Body is required.')
			return errors
		},
		altTextChanged() {
			let text = this.altText
			if ((this.bannerId && this.altText !== '') || null) {
				this.axios({
					method: 'patch',
					url: `/media/${this.bannerId}`,
					data: { altText: text },
				})
					.then((res) => {
						if (res.status === 404) {
							console.log(404)
						}

						if (res.status == 200 && res.data.success) {
							this.alert.message = res.data.message
						}
					})
					.catch((error) => {
						let errors = error.response.data
						this.alert.message = errors.message
					})
			}
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		editorChanged(body) {
			this.$v.body.$touch()
			this.body = body
		},
		dropzoneError(val) {
			console.log(val)
		},
		getBlog() {
			if (this.isEdit) {
				this.isSaving = true
				const projectId = this.$store.state.app.projectId
				this.axios.get(`/${projectId}/blogs/${this.id}`).then((res) => {
					if (res.status === 404) {
						console.log(404)
					}
					const data = res.data.data
					this.setBlogData(data)
					this.isSaving = false
				})
			}
		},
		setBlogData(blog) {
			this.banner = blog.banner
			this.bannerId = blog.banner ? blog.banner.media : null
			this.blogId = blog.id
			this.title = blog.title
			this.altText =
				blog.banner && this.banner.altText ? blog.banner.altText : null
			this.body = blog.body
			this.description = blog.description
			this.slug = blog.slug
			this.isPublished = blog.isPublished
			this.loading = false
			this.services = blog.services || []
			this.publicPath = blog.publicPath
		},
		validateData: function () {
			let isValid = true
			if (this.altText === '') {
				this.errors.altText = 'Alt Text field should not be empty.'
				isValid = false
			} else {
				this.errors.altText = ''
			}
			if (this.title === '') {
				this.errors.title = 'Title field should not be empty.'
				isValid = false
			} else {
				this.errors.title = ''
			}
			if (this.description === '') {
				this.errors.description =
					'Blog description should not be empty.'
				isValid = false
			}
			if (this.body === '') {
				this.errors.body = 'Blog Content should not be empty.'
				isValid = false
			} else {
				this.errors.body = ''
			}
			if (isValid) {
				this.saveData()
			}
		},
		saveData() {
			this.isSaving = true
			const isEdit = this.isEdit
			const projectId = this.$store.state.app.projectId

			this.altTextChanged() // when user upload banner later

			const payload = {
				title: this.title,
				description: this.description,
				body: this.body,
				banner: this.bannerId,
				services: this.services.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
			}
			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit
					? `/${projectId}/blogs/${this.blogId}`
					: `/${projectId}/blogs`,
				data: payload,
			})
				.then((res) => {
					this.isSaving = false
					if (res.status === 404) {
						console.log(404)
					}

					if (res.status == 200 && res.data.success) {
						const data = res.data.data
						this.isEdit = true
						this.setBlogData(data)
						this.alert.message = res.data.message
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		deleteBlog() {
			this.$store.dispatch('blog/deleteBlog', {
				blogId: this.id,
			})
			this.$router.push({ name: 'blog.list' })
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/blogs/toggle-status/${this.blogId}`,
			}).then(() => {
				this.isPublished = !this.isPublished
			})
		},
		blogListing() {
			this.$router.push({
				name: 'blog.list',
			})
		},
		updateService(array) {
			this.services = array
		},
	},
}
</script>
